<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddCategory
      :addCategoryDialog="addCategoryDialog"
      :StoreObj="StoreObj"
      @clicked="addCategoryDialogEmit"
    />
    <DeleteCategory
      :dialogDeleteCategory="dialogDeleteCategory"
      :StoreObj="StoreObj"
      @clicked="deleteItemConfirmEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" xs="12">
          <v-card-actions class="pb-0">
            <div class="PrimaryFontColor heading1">Category</div>
          </v-card-actions>
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12">
          <v-card-actions class="pb-0">
            <v-select
              outlined
              dense
              class="FontSize mt-2 field_label_size field_height maxWidthXSmall"
              label="Status"
              v-model="category_status"
              :items="StatusItems"
            ></v-select>
            <v-autocomplete
              persistent-hint
              :items="GetAllMasterCategoriesList"
              outlined
              dense
              label="Master Category"
              class="FontSize field_label_size field_height pl-2 mt-2"
              item-text="category_name"
              item-value="category_id"
              v-model="category_id"
            >
            </v-autocomplete>
          </v-card-actions>
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12">
          <v-card-actions class="pb-0">
            <v-text-field
              dense
              outlined
              class="FontSize mt-2 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="
                    (addCategoryDialog = true), (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="mt-n4 primary white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Category</span>
            </v-tooltip>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-card-text class="mt-n8">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :loading="loading"
          :items="GetAllCategoriesListBasedOnMC"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :no-data-text="noDataText"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
        <template v-slot:[`item.category_name`]="{ item }">
            <div class="FontSize">{{ item.category_name }}</div>
          </template>
          <template v-slot:[`item.category_icon_unicode`]="{ item }">
            <v-img max-width="30px" :src="item.category_icon_unicode"></v-img>
          </template>
          <template v-slot:[`item.category_ticket_prefix`]="{ item }">
            <div class="FontSize">{{ item.category_ticket_prefix }}</div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip
              bottom
              color="primary"
              v-if="category_status == 'ACTIVE'"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="primary"
                  @click="checkItem(item, 'EDIT')"
                  small
                  v-on="on"
                  class="mr-3"
                >
                  mdi-pencil-plus
                </v-icon>
              </template>
              <span class="white--text">Update Category Details</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="confirmDelete(item)"
                  :color="item.category_status == 'ACTIVE' ? 'red' : 'green'"
                  small
                  v-on="on"
                  class="red--text"
                >
                  {{
                    item.category_status == "ACTIVE" ? "mdi-close-thick" : "mdi-check"
                  }}
                </v-icon>
              </template>
              <span
                class="white--text"
                v-text="
                  item.category_status == 'ACTIVE'
                    ? 'De-Activate this Category ?'
                    : 'Activate this Category ?'
                "
              ></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllCategories } from "@/mixins/GetAllCategories.js";
import { GetCatagoryBasedOnMasterCatagory } from "@/mixins/GetCatagoryBasedOnMasterCatagory.js";
import Overlay from "@/components/Extras/Overlay.vue";
import AddCategory from "@/components/Category/Dialogs/AddCategory.vue";
import DeleteCategory from "@/components/Category/Dialogs/DeleteCategory.vue";
export default {
  components: {
    Overlay,
    AddCategory,
    DeleteCategory,
  },
  mixins: [GetAllCategories, GetCatagoryBasedOnMasterCatagory],
  data: () => ({
    TableHeight: 0,
    search: "",
    noDataText : "",
    category_status: "ACTIVE",
    GetAllCategoriesListBasedOnMC: [],
    StatusItems: ["ACTIVE", "INACTIVE"],
    headers: [
      { text: "", value: "category_icon_unicode", width: "30px" },
      { text: "Category Name", value: "category_name" },
      { text: "Ticket Prefix", value: "category_ticket_prefix" },
      { text: "Actions", value: "Actions" },
    ],
    overlay: false,
    loading: false,
    category_id: null,
    addCategoryDialog: false,
    dialogDeleteCategory: false,
    StoreObj: {},
  }),
  watch: {
    category_status(val) {
      this.GetAllCategoriesBasedOnMasterCatagoryMethod(this.category_id, val);
    },
    category_id(val) {
      this.GetAllCategoriesBasedOnMasterCatagoryMethod(
        val,
        this.category_status
      );
    },
  },
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.GetAllCategoriesBasedOnMasterCatagoryMethod(
      null,
      this.category_status
    );
    this.GetAllCategoriesMethod("ACTIVE", "MASTER_CATEGORY");
  },
  methods: {
    deleteItemConfirmEmit(Toggle) {
      this.dialogDeleteCategory = false;
      if (Toggle == 2) {
        this.GetAllCategoriesBasedOnMasterCatagoryMethod(
          this.category_id,
          this.category_status
        );
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      this.addCategoryDialog = true;
    },
    addCategoryDialogEmit(Toggle) {
      this.addCategoryDialog = false;
      if (Toggle == 2) {
        this.GetAllCategoriesBasedOnMasterCatagoryMethod(
          this.category_id,
          this.category_status
        );
      }
    },
    confirmDelete(item) {
      this.StoreObj = item;
      this.dialogDeleteCategory = true;
    },
  },
};
</script>
