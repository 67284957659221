var AWS = require("aws-sdk");
var s3Bucket = new AWS.S3({
  accessKeyId: "AKIARAJXABES432LKNPU",
  secretAccessKey: "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",
});
export const GetAllIcons = {
  data() {
    return {
      IconsList: [],
      commonPrefixes: [],
    };
  },
  methods: {
    async GetAllIconsMethod(Prefix, Type) {
      var params = {
        Bucket: "servicewrkcabinet",
        Prefix: Prefix,
        Delimiter: "/",
      };
      var self = this;
      s3Bucket.listObjects(params, function (err, data) {
        if (err) {
          console.warn("Error", err);
        } else {
          console.warn("Data", data);
          if (Type == "FOLDER") {
            for (let i = 0; i < data.CommonPrefixes.length; i++) {
              data.CommonPrefixes[i].Name = data.CommonPrefixes[
                i
              ].Prefix.replaceAll(params.Prefix, "").replaceAll("/", "");
              self.commonPrefixes.push(data.CommonPrefixes[i]);
            }
            console.warn("self.commonPrefixes", self.commonPrefixes);
            self.icon_category = self.commonPrefixes[0].Prefix;
          } else if (Type == "FILE") {
            self.IconsList = [];
            for (let i = 0; i < data.Contents.length; i++) {
              data.Contents[i].Name = data.Contents[i].Key.replaceAll(
                params.Prefix,
                ""
              ).replaceAll("/", "");
              data.Contents[
                i
              ].URL = `https://servicewrkcabinet.s3.ap-south-1.amazonaws.com/${data.Contents[i].Key}`;
              self.IconsList.push(data.Contents[i]);
            }
            // console.warn("self.commonPrefixes", self.IconsList);
          }
        }
      });
    },
  },
};
