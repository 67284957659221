<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDeleteCategory" width="400px">
      <v-card>
        <v-toolbar dense class="elevation-0" color="primary">
          <v-toolbar-title class="ml-2 white--text">{{
            StoreObj.category_status == "ACTIVE" ? "Deactivate" : "Activate"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="deleteItemConfirmEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text align="center">
          <v-icon
            size="50px"
            class="mt-4"
            :color="StoreObj.category_status == 'ACTIVE' ? 'red' : 'green'"
            >{{
              StoreObj.category_status == "ACTIVE" ? "mdi-close" : "mdi-check"
            }}</v-icon
          >
          <div>
            Are you sure you want to
            <span
              v-text="
                StoreObj.category_status == 'ACTIVE' ? 'Deactivate' : 'Activate'
              "
            ></span>
            {{ StoreObj.category_name }} ?
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            text
            @click="deleteItemConfirmEmit((Toggle = 1))"
            >Cancel</v-btn
          >
          <v-btn
            small
            :loading="isLoading"
            class="white--text primary"
            @click="deleteItemMethod()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ActivateOrDeactiveCategory } from "@/graphql/mutations.js";
export default {
  props: {
    StoreObj: Object,
    dialogDeleteCategory: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    isLoading: false,
    SnackBarComponent: {},
  }),
  methods: {
    async deleteItemMethod() {
      try {
        this.isLoading = true;
        var inputParams = {
          category_id: this.StoreObj.category_id,
          action:
            this.StoreObj.category_status == "ACTIVE"
              ? "DEACTIVATE"
              : "ACTIVATE",
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
        };
        let result = await API.graphql(
          graphqlOperation(ActivateOrDeactiveCategory, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.ActivateOrDeactiveCategory);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.deleteItemConfirmEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    deleteItemConfirmEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style>
.primary {
  color: #d97d54 !important;
}
</style>
